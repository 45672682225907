import revive_payload_client_4eeylFukdu from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.39_drizzle-orm@0.29.5_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_nT9WfZ09y3 from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.39_drizzle-orm@0.29.5_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_tMIIqP7x0z from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.39_drizzle-orm@0.29.5_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_DU7UuATMVD from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.39_drizzle-orm@0.29.5_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bh3dQHVoJC from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.39_drizzle-orm@0.29.5_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_WGwvY5oQ6n from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.39_drizzle-orm@0.29.5_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_TpuLmwQ5pu from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.39_drizzle-orm@0.29.5_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_FfAN1nlUNB from "/builds/ldseating/nido/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.1_typescript@5.5.3_vue@3.4.31/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/ldseating/nido/.nuxt/components.plugin.mjs";
import prefetch_client_QIJR0wqI8W from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.39_drizzle-orm@0.29.5_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_dcViqHLpp1 from "/builds/ldseating/nido/node_modules/.pnpm/@nuxt+ui@2.17.0_qrcode@1.5.3_rollup@4.18.1_vite@5.3.4_vue@3.4.31/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_RF4r9aj6OZ from "/builds/ldseating/nido/node_modules/.pnpm/@nuxt+ui@2.17.0_qrcode@1.5.3_rollup@4.18.1_vite@5.3.4_vue@3.4.31/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_4QgLJyJoyd from "/builds/ldseating/nido/node_modules/.pnpm/@nuxt+ui@2.17.0_qrcode@1.5.3_rollup@4.18.1_vite@5.3.4_vue@3.4.31/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_Gdjb9PRXJS from "/builds/ldseating/nido/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_rollup@4.18.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import i18n_x3zNa5vUA4 from "/builds/ldseating/nido/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.18.1_vue@3.4.31/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import sentry_client_shVUlIjFLk from "/builds/ldseating/nido/plugins/sentry.client.ts";
export default [
  revive_payload_client_4eeylFukdu,
  unhead_nT9WfZ09y3,
  router_tMIIqP7x0z,
  payload_client_DU7UuATMVD,
  navigation_repaint_client_bh3dQHVoJC,
  check_outdated_build_client_WGwvY5oQ6n,
  chunk_reload_client_TpuLmwQ5pu,
  plugin_vue3_FfAN1nlUNB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_QIJR0wqI8W,
  slideovers_dcViqHLpp1,
  modals_RF4r9aj6OZ,
  colors_4QgLJyJoyd,
  plugin_client_Gdjb9PRXJS,
  i18n_x3zNa5vUA4,
  sentry_client_shVUlIjFLk
]